import http from "../http.js";

const usersDataService = {
  me: () => {
    return http.get("/me/");
  },
  getUserById: (id) => {
    return http.get(`/user/${id}`);
  },
  getAllUsers: () => {
    return http.get(`/users/`);
  },
  editUser: (data) => {
    return http.post(`/user/edit`, data);
  },
  createUser: (data) => http.post("user/create", data),
  login: (data) => {
    return http.post("/login", data);
  },
};

export default usersDataService;
