import React from "react";

const FormErrors = ({ errors }) => {
  return errors.map((error) => {
    return (
      <>
        <div className="form-item ">
          <div className="form-item__label"></div>
          <div className="form-item__field alert alert-danger">
            {error.message}
          </div>
        </div>
      </>
    );
  });
};

export default FormErrors;
