import React from "react";
import usersDataService from "services/users";
import packsDataService from "services/packs";
import photoSetsDataService from "services/photoSets";
import { useParams, useNavigate } from "react-router-dom";
import LoadingBtn from "components/molecules/LoadingBtn";

const PhotoSetEdit = (props) => {
  const [users, setUsers] = React.useState([]);
  const [packs, setPacks] = React.useState([]);
  const [chargingPhotos, setChargingPhotos] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [photoSet, setPhotoSet] = React.useState(null);

  const { setid } = useParams();

  const navigate = useNavigate();

  const form = React.useRef(null);

  React.useEffect(() => {
    photoSetsDataService
      .getPhotoSet(setid)
      .then((res) => {
        setPhotoSet(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    usersDataService
      .getAllUsers()
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    packsDataService
      .getAllPacks()
      .then((res) => {
        setPacks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const editPhotoSet = (e, action) => {
    e.preventDefault();
    const data = new FormData(form.current);
    photoSetsDataService
      .editPhotoSet(data)
      .then((res) => {
        if (action) {
          const path =
            action === "set"
              ? `/photo-set/${setid}`
              : action === "list"
              ? `/photo-sets/list`
              : `/photo-set/edit/${setid}`;

          navigate(path);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const chargePhotosFromFolder = () => {
    setChargingPhotos(true);
    photoSetsDataService
      .chargePhotosFromFolder({ setId: setid })
      .then((res) => {
        alert("Photos charged successfully !!");
        setChargingPhotos(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="form-container">
        <form ref={form}>
          <div className="form-item">
            <div className="form-item__label">User: </div>
            <div className="form-item__field">
              <select name="userId">
                {users.map((user, key) => {
                  return (
                    <option
                      key={key}
                      value={user.userId}
                      selected={user.userId === photoSet.userId}
                    >
                      {user.firstname} {user.lastname}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Pack: </div>
            <div className="form-item__field">
              <select name="packId">
                {packs.map((pack, key) => {
                  return (
                    <option
                      key={key}
                      value={pack.packId}
                      selected={pack.packId === photoSet.packId}
                    >
                      {pack.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Date: </div>
            <div className="form-item__field">
              <input
                name="sessionDate"
                type="date"
                defaultValue={photoSet?.sessionDate.split("T")[0]}
              />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">valeur de développement : </div>
            <div className="form-item__field">
              <input
                name="printPrice"
                type="number"
                defaultValue={photoSet?.printPrice}
              />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Payée : </div>
            <div className="form-item__field">
              <input name="paid" type="number" defaultValue={photoSet?.paid} />
              <input name="setId" type="hidden" defaultValue={setid} />
            </div>
          </div>
          <div className="form-actions">
            <LoadingBtn
              appearance="primary"
              onClick={(e) => {
                editPhotoSet(e);
              }}
              loading={saving}
            >
              Save and stay
            </LoadingBtn>
            <LoadingBtn
              appearance="primary"
              onClick={(e) => {
                editPhotoSet(e, "set");
              }}
              loading={saving}
            >
              Save and back to set
            </LoadingBtn>
            <LoadingBtn
              appearance="primary"
              onClick={(e) => {
                editPhotoSet(e, "list");
              }}
              loading={saving}
            >
              Save and back to list
            </LoadingBtn>
          </div>
        </form>
        <div className="form-actions">
          <LoadingBtn
            appearance="primary"
            onClick={chargePhotosFromFolder}
            loading={chargingPhotos}
          >
            Charge photos from folder
          </LoadingBtn>
        </div>
      </div>
    </>
  );
};

export default PhotoSetEdit;
