import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";

const PhotoSetCard = ({ set }) => {
  const photosQty = set.photos ? set.photos.split(",").length - 1 : 0;
  const imgPath =
    set.setFormat === "new"
      ? `${process.env.REACT_APP_IMG_FOLDER}${set.setId}/`
      : process.env.REACT_APP_OLD_IMG_FOLDER;
  const coverImage = set.cover
    ? `${imgPath}${set.cover}-${
        set.setFormat === "new" ? "copy" : "copia"
      }-500.jpg`
    : "/img/logo.png";
  return (
    <div className="photo-set-card">
      <img src={coverImage} />
      <div className="photo-set-card__infos">
        <span className="photo-set-card__infos__item">
          {set.firstname} {set.lastname}
        </span>
        <span className="photo-set-card__infos__item">{set.packName}</span>
        <span className="photo-set-card__infos__item">{photosQty} photos</span>
      </div>
    </div>
  );
};

PhotoSetCard.propTypes = {
  set: propTypes.object,
  portada: propTypes.string,
};

export default PhotoSetCard;
