import axios from "axios";

const token = JSON.parse(localStorage.getItem("token"));

export default axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    "Content-type": "application/json",
    Authorization: token || "",
  },
});
