import http from "../http.js";

const packsDataService = {
  getPackById: (id) => {
    return http.get(`/pack/${id}`);
  },
  getAllPacks: () => {
    return http.get(`/packs/`);
  },
  editPack: (data) => {
    return http.post(`/editpack/`, data);
  },
  createPack: (data) => {
    return http.post(`/createpack/`, data);
  },
};

export default packsDataService;
