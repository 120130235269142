import React from "react";
import propTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import usersDataService from "services/users";
import LoadingBtn from "components/molecules/LoadingBtn";
import useRestrictedAccess from "helpers/useRestrictedAccess";
import FormErrors from "components/molecules/Forms/FormError";

const UserCreate = () => {
  const [user, setUser] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const form = React.useRef(null);
  const { userId } = useParams();
  const navigate = useNavigate();

  const createUser = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    usersDataService
      .createUser(data)
      .then((res) => {
        const errors = res.data.errors;
        setErrors(errors);
        if (!errors[0]) {
          const path = "/users/";
          navigate(path);
        }
        return;
      })
      .catch((err) => {
        alert(err.response.data.message);
        return navigate("/", { replace: true });
      });
  };

  return (
    <>
      <div className="form-container">
        <form ref={form}>
          {errors[0] && <FormErrors errors={errors} />}
          <div className="form-item">
            <div className="form-item__label">Name: </div>
            <div className="form-item__field">
              <input
                name="firstname"
                type="text"
                defaultValue={user?.firstname}
              />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Last name: </div>
            <div className="form-item__field">
              <input
                name="lastname"
                type="text"
                defaultValue={user?.lastname}
              />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">User name: </div>
            <div className="form-item__field">
              <input
                name="userName"
                type="text"
                defaultValue={user?.userName}
              />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Email: </div>
            <div className="form-item__field">
              <input name="email" type="text" defaultValue={user?.email} />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Password: </div>
            <div className="form-item__field">
              <input
                name="password"
                type="text"
                defaultValue={user?.password}
              />
            </div>
          </div>
          <div className="form-actions">
            <LoadingBtn
              appearance="primary"
              onClick={(e) => {
                createUser(e);
              }}
              loading={saving}
            >
              Create and back to list
            </LoadingBtn>
          </div>

          <input name="userId" type="hidden" defaultValue={userId} />
        </form>
        <div className="form-actions">
          {/* <LoadingBtn
            appearance="primary"
            onClick={chargePhotosFromFolder}
            loading={chargingPhotos}
          >
            Charge photos from folder
          </LoadingBtn> */}
        </div>
      </div>
    </>
  );
};

UserCreate.propTypes = {
  user: propTypes.object,
};

export default UserCreate;
