import React from "react";
import Btn from "components/atoms/Btn/Btn";
import Modal from "components/molecules/Modal";
import photoSetsDataService from "services/photoSets";
import { useNavigate } from "react-router-dom";

const RemoveSet = ({ children, setid }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [removing, setRemoving] = React.useState(false);

  const navigate = useNavigate();

  const handleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const removeSet = () => {
    setRemoving(true);
    photoSetsDataService
      .removeSet({ setId: setid })
      .then((res) => {
        setTimeout(() => {
          console.log(res.data);
          setModalIsOpen(false);
          setRemoving(false);

          navigate(`/photo-sets/list`);
        }, 5000);
      })
      .catch((err) => {
        setTimeout(() => {
          setModalIsOpen(false);
          setRemoving(false);
        }, 5000);
        console.log(err);
      });
  };
  return (
    <>
      <Btn onClick={handleModal}>{children}</Btn>
      <Modal
        title="Remove Set"
        modalIsOpen={modalIsOpen}
        handleModal={handleModal}
      >
        {removing ? (
          <div className="message">Removing...</div>
        ) : (
          <>
            <div className="message">
              Esta accion es irreversible !! <br />
              Estas seguro que deseas eliminar el set ?
            </div>
            <div className="actions">
              <Btn
                className="create-selection"
                appearance="primary"
                onClick={handleModal}
              >
                Annuler
              </Btn>
              <Btn
                className="create-selection"
                appearance="primary"
                onClick={removeSet}
              >
                Confirmer
              </Btn>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default RemoveSet;
