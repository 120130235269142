import React from "react";
import SelectionIcon from "../../SelectionIcon";
import LoadingIcon from "components/atoms/LoadingIcon";
import RemovePhoto from "../Actions/RemovePhoto";
import Icon from "components/atoms/Icon";

import { LightgalleryItem } from "react-lightgallery";

const EditionPhotoCard = ({
  photo,
  ident,
  isSelected,
  selectedPhotos,
  dispatchPhotoSet,
  setData,
  user,
}) => {
  const imgPath =
    setData.setFormat === "new"
      ? `${process.env.REACT_APP_IMG_FOLDER}${setData.setId}/`
      : process.env.REACT_APP_OLD_IMG_FOLDER;

  const imgType = setData.setFormat === "new" ? "copy" : "copia";
  const photoCard = React.useRef(null);

  const changeSetCover = (e) => {
    e.stopPropagation();
    if (setData.cover === photo) return;

    dispatchPhotoSet({
      type: "CHANGE_COVER",
      payload: {
        cover: photo,
      },
    });
  };

  return (
    <div className="photo-card" ref={photoCard}>
      <LightgalleryItem
        group="any"
        className="photo-card"
        src={`${imgPath}${photo}-${imgType}-1500.jpg`}
        thumb={`${imgPath}${photo}-${imgType}-500.jpg`}
        ident={ident}
        subHtml={photo}
      >
        <img src={`${imgPath}${photo}-${imgType}-500.jpg`} />
        <div className="photo-card-placeholder">
          <img src="/img/logo.png" />
          <LoadingIcon />
        </div>
        <span className="photo-card-ident">{ident}</span>
        {user.type === "superadmin" && setData.selectionState !== "complete" ? (
          <div
            className={`photo-card__cover-selection${
              setData.cover === photo ? " selected" : ""
            }`}
            onClick={changeSetCover}
          >
            <Icon icon="point" />
          </div>
        ) : null}
        {setData.selectionState !== "complete" && (
          <SelectionIcon
            isSelected={isSelected}
            selectedPhotos={selectedPhotos}
            dispatchPhotoSet={dispatchPhotoSet}
            photo={photo}
          />
        )}
        {user.type === "superadmin" && (
          <RemovePhoto
            setId={setData.setId}
            imageId={photo}
            dispatchPhotoSet={dispatchPhotoSet}
          />
        )}
      </LightgalleryItem>
    </div>
  );
};

export default EditionPhotoCard;
