import React from "react";
import usersDataService from "services/users";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import UserCard from "./UserCard";
import { useNavigate } from "react-router-dom";

const UsersList = ({ user }) => {
  const [users, setUsers] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    getUsers();
  }, []);

  console.log(users);

  const getUsers = async () => {
    await usersDataService
      .getAllUsers()
      .then((res) => {
        const data = res.data;
        setUsers(data);
      })
      .catch((err) => {
        alert(err.response.data.message);
        return navigate("/", { replace: true });
      });
  };
  return (
    <>
      <div className="users-list">
        {user.type === "superadmin" && (
          <div className="users-list__edition-controllers">
            <Link to={`/user/create`}>Create new user</Link>
          </div>
        )}
        {users &&
          users.map((user, key) => {
            return <UserCard key={key} user={user} />;
          })}
      </div>
    </>
  );
};

UsersList.propTypes = {
  user: propTypes.object,
};

export default UsersList;
