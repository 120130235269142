import React from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";

const UserCard = ({ user }) => {
  const { firstname, lastname, userName, email, type, state } = user;
  return (
    <div className="user-card">
      <div className="user-card__header">
        {firstname} {lastname}
      </div>
      <div className="user-card__content">
        <div className="user-card__info">
          <div className="user-card__info__item">
            <span>User name : </span>
            <span>{userName}</span>
          </div>
          <div className="user-card__info__item">
            <span>Email : </span>
            <span>{email}</span>
          </div>
          <div className="user-card__info__item">
            <span>Type : </span>
            <span>{type}</span>
          </div>
          <div className="user-card__info__item">
            <span>State : </span>
            <span>{state}</span>
          </div>
        </div>
        <div className="user-card__actions">
          <span className="user-card__actions__edit">
            <Link to={`/user/edit/${user.userId}`}>edit</Link>
          </span>
          <span className="user-card__actions__delete">delete</span>
        </div>
      </div>
    </div>
  );
};
UserCard.propTypes = {
  user: propTypes.object,
};

export default UserCard;
