import React from "react";
import propTypes from "prop-types";

const Icon = ({ icon = "candado" }) => {
  return <i className={`icon icon-${icon}`}></i>;
};

Icon.propTypes = {
  icon: propTypes.string,
};

export default Icon;
