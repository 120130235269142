import React from "react";
import propTypes from "prop-types";
import { LayoutProvider } from "./LayoutContext";

const GenericLayout = ({ children, header }) => {
    return (
        <LayoutProvider>
            <div className="generic-layout">
                {header}
                <div className="page-container">{children && children}</div>
            </div>
        </LayoutProvider>
    );
};

GenericLayout.propTypes = {
    children: propTypes.object,
    header: propTypes.object,
};

export default GenericLayout;
