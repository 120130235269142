import React from "react";
import propTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import packsDataService from "services/packs";
import LoadingBtn from "components/molecules/LoadingBtn";

const PackEdit = ({ user }) => {
  const [pack, setPack] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const form = React.useRef(null);
  const { packId } = useParams();
  const navigate = useNavigate();

  const packTypes = ["normal", "bon-cadeau", "free"];

  React.useEffect(() => {
    console.log(packId);
    getPack();
  }, []);

  const getPack = async () => {
    await packsDataService
      .getPackById(packId)
      .then((res) => {
        res.data.data = JSON.parse(res.data.data);
        setPack(res.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
        return navigate("/", { replace: true });
      });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    packsDataService
      .editPack(data)
      .then((res) => {
        const path = "/packs/";
        navigate(path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    pack && (
      <>
        <div className="form-container">
          <form ref={form}>
            <div className="form-item">
              <div className="form-item__label">Name: </div>
              <div className="form-item__field">
                <input name="name" type="text" defaultValue={pack?.name} />
              </div>
            </div>
            <div className="form-item">
              <div className="form-item__label">Type: </div>
              <div className="form-item__field">
                <select name="type">
                  <option value={null}>Select type</option>
                  {packTypes.map((type, key) => {
                    return (
                      <option
                        key={key}
                        value={type}
                        selected={type === pack?.type}
                      >
                        {type}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-item">
              <div className="form-item__label">packPrice : </div>
              <div className="form-item__field">
                <input
                  name="packPrice"
                  type="number"
                  defaultValue={pack?.data.packPrice}
                />
              </div>
            </div>
            <div className="form-item">
              <div className="form-item__label">photosPerPack : </div>
              <div className="form-item__field">
                <input
                  name="photosPerPack"
                  type="number"
                  defaultValue={pack?.data.photosPerPack}
                />
              </div>
            </div>
            <div className="form-item">
              <div className="form-item__label">additionalPhotoPrice : </div>
              <div className="form-item__field">
                <input
                  name="additionalPhotoPrice"
                  type="number"
                  defaultValue={pack?.data.additionalPhotoPrice}
                />
              </div>
            </div>
            <div className="form-item">
              <div className="form-item__label">promoPrice : </div>
              <div className="form-item__field">
                <input
                  name="promoPrice"
                  type="number"
                  defaultValue={pack?.data.promoPrice}
                />
              </div>
            </div>
            <div className="form-item">
              <div className="form-item__label">numberPhotosPromo : </div>
              <div className="form-item__field">
                <input
                  name="numberPhotosPromo"
                  type="number"
                  defaultValue={pack?.data.numberPhotosPromo}
                />
              </div>
            </div>
            <div className="form-actions">
              <LoadingBtn
                appearance="primary"
                onClick={(e) => {
                  saveChanges(e, "list");
                }}
                loading={saving}
              >
                Save and back to list
              </LoadingBtn>
            </div>

            <input name="packId" type="hidden" defaultValue={packId} />
          </form>
          <div className="form-actions">
            {/* <LoadingBtn
            appearance="primary"
            onClick={chargePhotosFromFolder}
            loading={chargingPhotos}
          >
            Charge photos from folder
          </LoadingBtn> */}
          </div>
        </div>
      </>
    )
  );
};

PackEdit.propTypes = {
  user: propTypes.object,
};

export default PackEdit;
