import { Pagination, Navigation, HashNavigation } from "swiper";

export default {
  spaceBetween: 20,
  slidesPerView: 1,
  loop: false,
  speed: 1000,
  pagination: {
    clickable: true,
  },
  modules: [Pagination, Navigation, HashNavigation],
};
