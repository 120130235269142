import React from "react";
import propTypes from "prop-types";
import Loading from "components/molecules/Loading";

const LoginForm = ({ formRef, ...props }) => {
  return (
    <div className="login">
      <div className="login-logo">
        <a href={process.env.REACT_APP_SITE_URI}>
          <img src="/img/logo.png" />
        </a>
      </div>

      {props.loading ? (
        <Loading />
      ) : (
        <form ref={formRef} className="login__form">
          <label>
            <h3>Galerie photos privée</h3>
          </label>
          <input type="text" name="user" />
          <input type="password" name="password" />
          <button className="btn btn-primary" onClick={props.login}>
            Se connecter
          </button>
          <a className="btn" href={process.env.REACT_APP_SITE_URI}>
            Revenir sur le site
          </a>
        </form>
      )}
    </div>
  );
};

LoginForm.propTypes = {
  login: propTypes.func,
  loading: propTypes.bool,
  formRef: propTypes.object,
};

export default LoginForm;
