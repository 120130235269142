import React from "react";
import propTypes from "prop-types";
import Icon from "components/atoms/Icon";

const SelectionIcon = ({
  isSelected,
  selectedPhotos,
  dispatchPhotoSet,
  photo,
}) => {
  const icon = isSelected ? "star_rellena" : "star";

  const handleClick = (e) => {
    e.stopPropagation();
    const newArr = selectedPhotos.includes(photo)
      ? selectedPhotos.filter((item) => item !== photo)
      : [...selectedPhotos, photo];

    dispatchPhotoSet({
      type: "SET_SELECTED_PHOTOS",
      payload: newArr,
    });
  };
  return (
    <div className="selection-icon" onClick={handleClick}>
      <Icon icon={icon} />
    </div>
  );
};

SelectionIcon.propTypes = {
  isSelected: propTypes.bool,
  selectedPhotos: propTypes.array,
  dispatchPhotoSet: propTypes.func,
  photo: propTypes.string,
};

export default SelectionIcon;
