import React from "react";
import propTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import packsDataService from "services/packs";
import LoadingBtn from "components/molecules/LoadingBtn";

const PackCreate = ({ user }) => {
  const [saving, setSaving] = React.useState(false);
  const form = React.useRef(null);
  const { packId } = useParams();
  const navigate = useNavigate();

  const packTypes = ["normal", "bon-cadeau", "free"];

  const createPack = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    packsDataService
      .createPack(data)
      .then((res) => {
        const path = "/packs/";
        navigate(path);
      })
      .catch((err) => {
        alert(err.response.data.message);
        return navigate("/", { replace: true });
      });
  };

  return (
    <>
      <div className="form-container">
        <form ref={form}>
          <div className="form-item">
            <div className="form-item__label">Name: </div>
            <div className="form-item__field">
              <input name="name" type="text" />
            </div>
          </div>

          <div className="form-item">
            <div className="form-item__label">Type: </div>
            <div className="form-item__field">
              <select name="type">
                <option value={null}>Select type</option>
                {packTypes.map((type, key) => {
                  return (
                    <option key={key} value={type}>
                      {type}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">packPrice : </div>
            <div className="form-item__field">
              <input name="packPrice" type="number" />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">photosPerPack : </div>
            <div className="form-item__field">
              <input name="photosPerPack" type="number" />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">additionalPhotoPrice : </div>
            <div className="form-item__field">
              <input name="additionalPhotoPrice" type="number" />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">promoPrice : </div>
            <div className="form-item__field">
              <input name="promoPrice" type="number" />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">numberPhotosPromo : </div>
            <div className="form-item__field">
              <input name="numberPhotosPromo" type="number" />
            </div>
          </div>
          <div className="form-actions">
            <LoadingBtn
              appearance="primary"
              onClick={(e) => {
                createPack(e);
              }}
              loading={saving}
            >
              Create and back to list
            </LoadingBtn>
          </div>
        </form>
        <div className="form-actions">
          {/* <LoadingBtn
            appearance="primary"
            onClick={chargePhotosFromFolder}
            loading={chargingPhotos}
          >
            Charge photos from folder
          </LoadingBtn> */}
        </div>
      </div>
    </>
  );
};

PackCreate.propTypes = {
  user: propTypes.object,
};

export default PackCreate;
