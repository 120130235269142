import React from "react";
import propTypes from "prop-types";

const Billing = () => {
  return (
    <div className="app">
      <h1>Billing page</h1>
    </div>
  );
};

Billing.propTypes = {};

export default Billing;
