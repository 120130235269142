import React from "react";
import propTypes from "prop-types";

const Gifts = () => {
  return (
    <div className="app">
      <h1>Gifts page</h1>
    </div>
  );
};

Gifts.propTypes = {};

export default Gifts;
