import React, { useEffect, useReducer, useContext } from "react";
import photoSetsDataService from "services/photoSets";
import { useParams, Link } from "react-router-dom";
import Loading from "components/molecules/Loading";
import "lightgallery.js/dist/css/lightgallery.css";
import photoSetReducer from "./photoSetReducer";
import BillingPanel from "./BillingPanel";
import Btn from "components/atoms/Btn/Btn";
import propTypes from "prop-types";
import Modal from "components/molecules/Modal";
import LoadingBtn from "components/molecules/LoadingBtn";
import modalContents from "./ModalContents";
import calculateBillingData from "./helpers/calculateBillingData";
import Icon from "components/atoms/Icon";
import EditionPhotoSlider from "./Components/Slider/EditionPhotoSlider";
import RemoveSet from "./Components/Actions/RemoveSet";

const PhotoSet = ({ user }) => {
  const { setid } = useParams();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [creatingLink, setCreatingLink] = React.useState(false);
  const [modalContent, setModalContent] = React.useState();
  const [orderedPhotosToShow, setOrderedPhotosToShow] = React.useState();

  const handleModal = (e) => {
    setModalIsOpen(!modalIsOpen);
  };

  const resolveItems = (qty, itemsToOrder) => {
    if (!itemsToOrder) return [];
    const arr = [];
    const quantity = Math.ceil(itemsToOrder.length / qty);
    for (let i = 0; i < quantity; i++) {
      arr.push(itemsToOrder.slice(i * qty, (i + 1) * qty));
    }
    return arr;
  };

  const [photoSet, dispatchPhotoSet] = useReducer(photoSetReducer, {
    activePhotoId: null,
    photosToShow: null,
    photos: null,
    selectedPhotos: null,
    savedSelection: null,
    filters: {
      order: "asc",
      show: "all",
    },
    loading: true,
    error: false,
    billingData: null,
    cover: null,
  });

  const {
    activePhotoId,
    photosToShow,
    photos,
    selectedPhotos,
    savedSelection,
    selectionState,
    filters,
    loading,
    error,
    billingData,
    setData,
    cover,
  } = photoSet;

  useEffect(() => {
    getPhotoSetData(setid);
  }, []);

  useEffect(() => {
    if (loading) return;
    dispatchPhotoSet({
      type: "APPLY_FILTERS",
    });
  }, [filters, selectedPhotos]);

  const getPhotoSetData = async () => {
    dispatchPhotoSet({
      type: "FETCH_PHOTOSET_INIT",
    });
    await photoSetsDataService
      .getPhotoSet(setid)
      .then((res) => {
        dispatchPhotoSet({
          type: "FETCH_PHOTOSET_SUCCESS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirmSelection = () => {
    setModalContent("confirmSelection");
    setModalIsOpen(true);
  };

  const handlecreateSelection = async () => {
    await handleSaveSelection();

    setModalIsOpen(false);
    photoSetsDataService
      .createPhotosSelection({ setid })
      .then((res) => {
        dispatchPhotoSet({
          type: "CREATE_SELECTION",
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(`    
    we'll go to create a selection with those photos : 
    ${selectedPhotos}
    `);
  };

  const handleSaveSelection = async () => {
    setIsSaving(true);
    const dataToSave = {
      photoSelection: selectedPhotos.length
        ? selectedPhotos.join(",") + ","
        : "",
      setid: setid,
      cover: setData.cover,
    };

    await photoSetsDataService
      .updatePhotosSelection(dataToSave)
      .then((res) => {
        setIsSaving(false);
        dispatchPhotoSet({
          type: "SAVE_SELECTION",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsSaving(false);
      });
  };
  const handleModifySelection = () => {
    photoSetsDataService
      .modifySelection({ setId: setid })
      .then((res) => {
        console.log(res.data);
        dispatchPhotoSet({
          type: "MODIFY_SELECTION",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlecreateDownLoadLink = () => {
    setCreatingLink(true);
    photoSetsDataService
      .createDownloadLink({ setid })
      .then((res) => {
        setTimeout(() => {
          setCreatingLink(false);
          dispatchPhotoSet({
            type: "CREATE_DOWNLOAD_LINK",
          });
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const totalToPay = selectedPhotos
    ? calculateBillingData(billingData, selectedPhotos)
    : 0;

  const downloadLinkRejection = () => {
    setModalContent("downloadLinkRejection");
    setModalIsOpen(true);
  };

  const modals = modalContents({
    handleModal,
    handlecreateSelection,
    handlecreateDownLoadLink,
    photosData: {
      photosPerPack: billingData?.photosPerPack,
      selectedPhotosQty: selectedPhotos?.length,
    },
    totalToPay,
  });

  React.useEffect(() => {
    setOrderedPhotosToShow(resolveItems(8, photosToShow));
  }, [photosToShow]);

  return loading ? (
    <Loading />
  ) : (
    <div className="photo-sets">
      <div className="top-panel photo-sets__top-panel">
        <div className="ctn">
          <BillingPanel selectedPhotos={selectedPhotos} data={billingData} />
        </div>
      </div>
      {user.type === "superadmin" && (
        <div className="photo-sets__edition-actions ctn">
          <div className="photo-sets__edition-actions__buttons">
            <Link to={`/photo-set/edit/${setid}`}>
              <Icon icon="editar" />
            </Link>
            <RemoveSet setid={setid}>
              <Icon icon="eliminar" />
            </RemoveSet>
          </div>
        </div>
      )}
      <div className="photo-sets-container ">
        <div className="photo-sets-container__inner ctn">
          <EditionPhotoSlider
            orderedPhotosToShow={orderedPhotosToShow}
            selectedPhotos={selectedPhotos}
            dispatchPhotoSet={dispatchPhotoSet}
            setData={setData}
            user={user}
            selectionState={selectionState}
            activePhotoId={activePhotoId}
          />
          <div className="photo-sets__actions">
            {selectionState !== "complete" ? (
              <>
                {selectedPhotos.length ? (
                  <Btn
                    className="create-selection"
                    appearance="primary"
                    onClick={handleConfirmSelection}
                  >
                    Créer sélection
                  </Btn>
                ) : null}

                {JSON.stringify(savedSelection?.sort()) !=
                  JSON.stringify(selectedPhotos.sort()) ||
                cover !== setData.cover ? (
                  <LoadingBtn
                    appearance="primary"
                    onClick={handleSaveSelection}
                    loading={isSaving}
                  >
                    Sauvegarder
                  </LoadingBtn>
                ) : null}
              </>
            ) : (
              <>
                {setData.downloadLinkId === "" ? (
                  <>
                    <LoadingBtn
                      appearance="primary"
                      onClick={
                        totalToPay <= 0
                          ? handlecreateDownLoadLink
                          : downloadLinkRejection
                      }
                      loading={creatingLink}
                    >
                      Créer Lien de téléchargement
                    </LoadingBtn>
                  </>
                ) : (
                  <a
                    className="btn btn-primary"
                    href={`${process.env.REACT_APP_DOWNLOADS_FOLDER}zip/${setData.downloadLinkId}.zip`}
                  >
                    Télécharger mes photos
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        title={modals[modalContent]?.title}
        modalIsOpen={modalIsOpen}
        handleModal={handleModal}
      >
        {modals[modalContent]?.component}
      </Modal>
    </div>
  );
};

PhotoSet.propTypes = {
  user: propTypes.object,
};

export default PhotoSet;
