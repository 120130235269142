import React from "react";
import propTypes from "prop-types";

const Selections = () => {
  return (
    <div className="app">
      <h1>Selections page</h1>
    </div>
  );
};

Selections.propTypes = {};

export default Selections;
