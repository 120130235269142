import React from "react";
import propTypes from "prop-types";

const Offers = () => {
  return (
    <div className="app">
      <h1>Offers page</h1>
    </div>
  );
};

Offers.propTypes = {};

export default Offers;
