import React from "react";
import propTypes from "prop-types";

const BillingPanel = ({ data, selectedPhotos }) => {
  const selectedQty = selectedPhotos.length;
  const selectedFromPack =
    selectedQty >= data.photosPerPack ? data.photosPerPack : selectedQty;
  const additionalSelected =
    selectedQty > data.photosPerPack ? selectedQty - data.photosPerPack : 0;

  const calculateAdditionalSelectedPrice = () => {
    const promosNumber = Math.floor(
      additionalSelected / data.numberPhotosPromo
    );

    const rest = additionalSelected - promosNumber * data.numberPhotosPromo;

    const promosPrice = promosNumber * data.promoPrice;

    const additionalsPrice = rest * data.additionalPhotoPrice;

    return promosPrice + additionalsPrice;
  };

  const additionalSelectedPrice = calculateAdditionalSelectedPrice();

  const calculateTotalToPay = () => {
    const totalPrices =
      data.packPrice + data.printPrice + additionalSelectedPrice;

    const totalToPay = totalPrices - data.paid;

    return totalToPay;
  };

  const totalToPay = calculateTotalToPay();

  return (
    <div className="billing-panel">
      {/* <div className="billing-panel__title">
        <h3 className="h4">Billing panel</h3>
      </div> */}
      <div className="billing-panel__panels">
        <div className="billing-panel__inner">
          <div className="billing-panel__panels-left">
            <div>
              <span>Photos du pack :</span>{" "}
              <span>
                {selectedFromPack}/{data.photosPerPack}
              </span>
            </div>
            <div>
              <span>Photos additionnelles :</span>{" "}
              <span>{`${additionalSelected} (${data.additionalPhotoPrice} € l'unité et ${data.promoPrice} € les ${data.numberPhotosPromo})`}</span>
            </div>
            {/* <div>
              <span>Valeur des photos additionnelles :</span>{" "}
              <span>{data.additionalPhotoPrice} €</span>
            </div> */}
            <div>
              <span>Total photos :</span> <span>{selectedQty}</span>
            </div>
          </div>
          <div className="billing-panel__panels-right">
            {data.packType !== "bon-cadeau" && (
              <>
                <div>
                  <span>Valeur du pack :</span> <span>{data.packPrice} €</span>
                </div>
                <div>
                  <span>Valeur du tirage :</span>{" "}
                  <span>{data.printPrice} €</span>
                </div>

                <div>
                  <span>Valeur des photos additionnelles :</span>{" "}
                  <span>{additionalSelectedPrice} €</span>
                </div>
                <div>
                  <span>Montant payé :</span> <span>{data.paid} €</span>
                </div>
              </>
            )}
            <div className="billing-panel__panels-total">
              <span>Montant à payer :</span> <span>{totalToPay} €</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BillingPanel.propTypes = {
  data: propTypes.object,
  selectedPhotos: propTypes.array,
};

export default BillingPanel;
