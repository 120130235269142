import http from "../http.js";

const photoSetsDataService = {
  getPhotoSets: () => {
    return http.get(`/getphotosets/`);
  },
  getPhotoSet: (setid) => {
    return http.get(`/getphotoset/${setid}/`);
  },
  updatePhotosSelection: (body) => {
    return http.post(`/update-photos-selection/`, body);
  },
  unselectPhoto: () => {
    return http.post(`/unselectphoto/`);
  },
  createPhotosSelection: (body) => http.post(`/create-photos-selection/`, body),
  createPhotoSet: (data) => http.post("/create-photo-set/", data),
  editPhotoSet: (data) => http.post("/edit-photo-set/", data),
  chargePhotosFromFolder: (data) =>
    http.post("/charge-photos-from-folder/", data),
  createDownloadLink: (data) => http.post("/create-download-link/", data),
  modifySelection: (data) => http.post("/modify-selection/", data),
  removePhoto: (data) => http.post("/remove-photo/", data),
  removeSet: (data) => http.post("/remove-set/", data),
};

export default photoSetsDataService;
