import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";

const Home = ({ user }) => {
  return (
    <div className="app">
      <p>Welcome {`${user.firstname} ${user.lastname}`} to</p>
      <h1>Pascale Pyot Photo App</h1>
      <p>Is coming soom ! </p>
    </div>
  );
};

Home.propTypes = {
  user: propTypes.object,
};

export default Home;
