import React from "react";
import EditionPhotoCard from "../Cards/EditionPhotoCard";

const EditionSlide = ({ item, selectedPhotos, ...rest }) => {
  return (
    <div className="cards-group">
      {item.map((card) => {
        const isSelected = selectedPhotos.includes(card.photo);
        return (
          <EditionPhotoCard
            key={card.id}
            ident={card.id + 1}
            photo={card.photo}
            isSelected={isSelected}
            selectedPhotos={selectedPhotos}
            {...rest}
          />
        );
      })}
    </div>
  );
};

export default EditionSlide;
