import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import photoSetsDataService from "services/photoSets";
import PhotoSetCard from "components/molecules/PhotoSetCard";
import { Link } from "react-router-dom";
import Loading from "components/molecules/Loading";

const PhotoSets = ({ user }) => {
  const [photoSets, setPhotoSets] = useState(null);

  useEffect(() => {
    getPhotoSets();
  }, []);

  const getPhotoSets = async () => {
    await photoSetsDataService
      .getPhotoSets()
      .then((res) => {
        setPhotoSets(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="photo-sets photo-sets__list">
      {user.type === "superadmin" && (
        <div className="photo-sets__edition-controllers">
          <Link to={`/photo-set/create`}>create new set</Link>
        </div>
      )}

      {photoSets ? (
        photoSets?.map((set, k) => {
          return (
            <Link to={`/photo-set/${set.setId}`} key={k}>
              <PhotoSetCard set={set} />
            </Link>
          );
        })
      ) : (
        <Loading />
      )}
    </div>
  );
};

PhotoSets.propTypes = {
  user: propTypes.object,
};

export default PhotoSets;
