import React from "react";
import propTypes from "prop-types";
import photoSetsDataService from "services/photoSets";
import Btn from "components/atoms/Btn/Btn";

const RemovePhoto = ({ setId, imageId, dispatchPhotoSet }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const confirmRemove = (e) => {
    e.stopPropagation();
    setModalOpen(false);
    photoSetsDataService
      .removePhoto({ setId, imageId })
      .then((res) => {
        dispatchPhotoSet({
          type: "REMOVE_PHOTO",
          payload: {
            imageId: imageId,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancel = (e) => {
    e.stopPropagation();
    setModalOpen(false);
  };

  const askToConfirm = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };
  return (
    <div className="photo-card__remove">
      <span className="photo-card__remove__icon" onClick={askToConfirm}>
        X
      </span>
      <div
        className={`photo-card__remove__confirm-modal${
          modalOpen ? " open" : ""
        }`}
      >
        <div className="photo-card__remove__confirm-modal__message">
          Are you really sure ?
        </div>
        <div className="photo-card__remove__confirm-modal__actions">
          <Btn appearance="primary" onClick={handleCancel}>
            No
          </Btn>
          <Btn appearance="primary" onClick={confirmRemove}>
            Yes
          </Btn>
        </div>
      </div>
    </div>
  );
};

RemovePhoto.propTypes = {
  setId: propTypes.string,
  imageId: propTypes.string,
  dispatchPhotoSet: propTypes.func,
};

export default RemovePhoto;
