import React from "react";
import usersDataService from "services/users";
import packsDataService from "services/packs";
import photoSetsDataService from "services/photoSets";
import { useNavigate } from "react-router";
import LoadingBtn from "components/molecules/LoadingBtn";

const PhotoSetCreate = () => {
  const [users, setUsers] = React.useState([]);
  const [packs, setPacks] = React.useState([]);
  const [creating, setCreating] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    usersDataService
      .getAllUsers()
      .then((res) => {
        setUsers(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    packsDataService
      .getAllPacks()
      .then((res) => {
        setPacks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formCreateSet = React.useRef(null);

  const createPhotoSet = (e) => {
    e.preventDefault();
    setCreating(true);
    const form = formCreateSet.current;
    const data = new FormData(form);

    photoSetsDataService
      .createPhotoSet(data)
      .then((res) => {
        console.log(res.data);
        navigate("/photo-sets/list");
        setCreating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="form-container">
        <form ref={formCreateSet}>
          <div className="form-item">
            <div className="form-item__label">User: </div>
            <div className="form-item__field">
              <select name="userId">
                <option value={null}>Select user</option>
                {users.map((user, key) => {
                  return (
                    <option key={key} value={user.userId}>
                      {user.firstname} {user.lastname}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Pack: </div>
            <div className="form-item__field">
              <select name="packId" id="">
                <option value={null}>Select pack</option>
                {packs.map((pack, key) => {
                  return (
                    <option key={key} value={pack.packId}>
                      {pack.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Date:</div>
            <div className="form-item__field">
              <input name="sessionDate" type="date" />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">valeur de développement : </div>
            <div className="form-item__field">
              <input name="printPrice" type="number" defaultValue={0} />
            </div>
          </div>
          <div className="form-item">
            <div className="form-item__label">Payée :</div>
            <div className="form-item__field">
              <input name="paid" type="number" defaultValue={0} />
            </div>
          </div>

          <div className="form-actions">
            <LoadingBtn
              appearance="primary"
              onClick={createPhotoSet}
              loading={creating}
            >
              Create
            </LoadingBtn>
          </div>
        </form>
      </div>
    </>
  );
};

export default PhotoSetCreate;
