const calculateBillingData = (data, selectedPhotos) => {
  const selectedQty = selectedPhotos.length;
  const selectedFromPack =
    selectedQty >= data.photosPerPack ? data.photosPerPack : selectedQty;
  const additionalSelected =
    selectedQty > data.photosPerPack ? selectedQty - data.photosPerPack : 0;

  const promosNumber = Math.floor(additionalSelected / data.numberPhotosPromo);

  const rest = additionalSelected - promosNumber * data.numberPhotosPromo;

  const promosPrice = promosNumber * data.promoPrice;

  const additionalsPrice = rest * data.additionalPhotoPrice;
  const additionalSelectedPrice = promosPrice + additionalsPrice;

  const totalPrices =
    data.packPrice + data.printPrice + additionalSelectedPrice;

  const totalToPay = totalPrices - data.paid;

  return totalToPay;
};

export default calculateBillingData;
