import React from "react";
import cx from "classnames";
import propTypes from "prop-types";
import Icon from "components/atoms/Icon";
import ItemUserNav from "components/molecules/ItemUserNav";

const Navigator = ({ toggleNav, isNavOpen, user }) => {
  const classes = cx({
    navigator: true,
    open: isNavOpen,
  });

  const classOverlay = cx({
    "navigator-overlay": true,
    open: isNavOpen,
  });

  return (
    <>
      <div className={classes}>
        <div className="close-btn" onClick={toggleNav}>
          <Icon icon="cerrar" />
        </div>
        <div className="navigator-user-info">
          <div className="navigator-user-info__icon">
            <Icon icon="user" />
          </div>
          <div className="navigator-user-info__user-name">
            <h2>{`${user.firstname} ${user.lastname}`}</h2>
          </div>
        </div>

        <div className="navigator-menu-container">
          <div className="navigator-menu__items-list">
            <ItemUserNav
              icon="point"
              label="Accueil"
              path="accueil"
              toggleNav={toggleNav}
            />
            {/* <ItemUserNav
              icon="point"
              label="Pascale Pyot Web Site"
              toggleNav={toggleNav}
              href={process.env.REACT_APP_SITE_URI}
            /> */}
          </div>

          {user.type === "superadmin" && (
            <div className="navigator-menu__items-list">
              <ItemUserNav
                icon="user"
                label="Utilisateurs"
                path="users"
                toggleNav={toggleNav}
              />
            </div>
          )}
          {/* <div className="navigator-menu__items-list">
            <ItemUserNav
              icon="point"
              label="Account details"
              path="account-details"
              toggleNav={toggleNav}
            />
            <ItemUserNav
              icon="seo"
              label="My informations"
              path="informations"
              toggleNav={toggleNav}
            />
            <ItemUserNav
              icon="star"
              label="Bons Cadeau"
              path="gifts"
              toggleNav={toggleNav}
            />
            <ItemUserNav
              icon="eliminar"
              label="Special offers"
              path="offers"
              toggleNav={toggleNav}
            />
          </div> */}
          {/* <div className="navigator-menu__items-list">
            <ItemUserNav
              icon="editar"
              label="Facturation"
              path="billing"
              toggleNav={toggleNav}
            />
            <ItemUserNav
              icon="descargar"
              label="Cartes de credit"
              path="credit-cards"
              toggleNav={toggleNav}
            />
          </div> */}
          <div className="navigator-menu__items-list">
            {user.type === "superadmin" && (
              <ItemUserNav
                icon="editar"
                label="Packs"
                path="packs"
                toggleNav={toggleNav}
              />
            )}

            <ItemUserNav
              icon="editar"
              label="Sets photos"
              path="photo-sets/list"
              toggleNav={toggleNav}
            />
            {/* <ItemUserNav
              icon="descargar"
              label="Selections"
              path="selections"
              toggleNav={toggleNav}
            /> */}
          </div>
          <div className="navigator-menu__items-list">
            <ItemUserNav
              icon="candado"
              label="Fermer session"
              path="logout"
              toggleNav={toggleNav}
            />
          </div>
        </div>

        {/* <div className="navigator__open-btn-container">
          <div className="open-btn" onClick={toggleNav}>
            <span className="icon">{"<"}</span>
          </div>
        </div> */}
      </div>
      <div className={classOverlay} onClick={toggleNav}></div>
    </>
  );
};

Navigator.propTypes = {
  toggleNav: propTypes.func,
  isNavOpen: propTypes.bool,
  logout: propTypes.func,
  user: propTypes.object,
};

export default Navigator;
