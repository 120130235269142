import React from "react";
import { CameraLoadingIcon } from "components/atoms/LoadingIcon";
import propTypes from "prop-types";

const LoadingBtn = ({ appearance, children, onClick, loading }) => {
  return (
    <button
      className={`loading-btn  btn${
        appearance ? ` btn-${appearance}` : " default"
      }${loading ? " onloading" : ""}`}
      onClick={onClick}
    >
      {/* {loading ? <LoadingIcon /> : children} */}
      <span className="loading-btn__label">{children}</span>
      <CameraLoadingIcon />
    </button>
  );
};

LoadingBtn.propTypes = {
  appearance: propTypes.string,
  children: propTypes.string,
  onClick: propTypes.func,
  loading: propTypes.bool,
};

export default LoadingBtn;
