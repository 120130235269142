import React, { useEffect } from "react";
import propTypes from "prop-types";

const Logout = ({ logout }) => {
  useEffect(() => {
    logout();
  }, []);
  return <div className="app"></div>;
};

Logout.propTypes = {
  logout: propTypes.func,
};

export default Logout;
