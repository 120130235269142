import React from "react";
import propTypes from "prop-types";
import packsDataService from "services/packs";
import Loading from "components/molecules/Loading";
import PackCard from "./PackCard";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PacksList = ({ user }) => {
  const [packs, setPacks] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    getPacksList();
  }, []);

  const getPacksList = async () => {
    await packsDataService
      .getAllPacks()
      .then((res) => {
        const data = res.data;
        setPacks(data);
      })
      .catch((err) => {
        alert(err.response.data.message);
        return navigate("/", { replace: true });
      });
  };

  return (
    <div className="packs-list">
      {user.type === "superadmin" && (
        <div className="packs-list__edition-controllers">
          <Link to={`/pack/create`}>Create new pack</Link>
        </div>
      )}
      {packs ? (
        packs.map((packData, key) => {
          return <PackCard key={key} packData={packData} />;
        })
      ) : (
        <Loading />
      )}
    </div>
  );
};

PacksList.propTypes = {
  user: propTypes.object,
};

export default PacksList;
