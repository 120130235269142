import React from "react";
import propTypes from "prop-types";
import classnames from "classnames";

const Modal = ({ title, modalIsOpen, handleModal, children, footer }) => {
  const classes = classnames({
    modal: true,
    "modal-open": modalIsOpen,
  });

  React.useEffect(() => {
    if (!document) return;
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }

    return () => {
      document.body.style.overflow = null;
    };
  }, [modalIsOpen]);
  return (
    <>
      <div className={classes}>
        <div className="modal__content">
          <div className="modal__header">
            <h1 className="modal__header__title">{title}</h1>
            <span className="modal__header__close" onClick={handleModal}>
              X
            </span>
          </div>
          <div className="modal__body">{children}</div>
          {footer && <div className="modal__footer">{footer}</div>}
        </div>
        <div className="modal__overlay" onClick={handleModal}></div>
      </div>
    </>
  );
};

Modal.propTypes = {
  title: propTypes.string,
  modalIsOpen: propTypes.bool,
  handleModal: propTypes.func,
  children: propTypes.string,
  footer: propTypes.string,
};

export default Modal;
