import React from "react";
import propTypes from "prop-types";
import Icon from "components/atoms/Icon";
import { Link } from "react-router-dom";

const ItemUserNav = ({
  label = "nolabel",
  icon,
  path = "",
  toggleNav,
  href = null,
}) => {
  return href ? (
    <a href={href} className="item-user-nav">
      <Icon icon={icon} />
      <label>{label}</label>
    </a>
  ) : (
    <Link to={`/${path}`} className="item-user-nav" onClick={toggleNav}>
      <Icon icon={icon} />
      <label>{label}</label>
    </Link>
  );
};

ItemUserNav.propTypes = {
  label: propTypes.string,
  icon: propTypes.string,
  path: propTypes.string,
  toggleNav: propTypes.func,
  href: propTypes.string,
};

export default ItemUserNav;
