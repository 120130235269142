import React from "react";
import SliderOne from "components/organisms/SliderOne/SliderOne";
import sliderConf from "./sliderConf";
import EditionSlide from "./EditionSlide";
import { LightgalleryProvider } from "react-lightgallery";
import SelectionIcon from "../../SelectionIcon";

const EditionPhotoSlider = ({
  orderedPhotosToShow,
  selectedPhotos,
  dispatchPhotoSet,
  setData,
  user,
  selectionState,
  activePhotoId,
}) => {
  return (
    <>
      <LightgalleryProvider
        lightgallerySettings={{
          // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html

          // https://github.com/VLZH/react-lightgallery
          download: false,
        }}
        onSlideItemLoad={(event, lightgallery_object) => {
          dispatchPhotoSet({
            type: "SET_ACTIVE_PHOTO",
            payload: document.querySelector(".lg-sub-html").innerHTML,
          });
        }}
        onAfterSlide={(event, lightgallery_object) => {
          dispatchPhotoSet({
            type: "SET_ACTIVE_PHOTO",
            payload: document.querySelector(".lg-sub-html").innerHTML,
          });
        }}
        onBeforeClose={() => {
          dispatchPhotoSet({
            type: "UNSET_ACTIVE_PHOTO",
          });
        }}
      >
        {orderedPhotosToShow.length ? (
          <div className="images-grid-item">
            <SliderOne
              sliderConf={{
                ...sliderConf,
                navigation: {
                  prevEl: ".swiper-button-prev",
                  nextEl: ".swiper-button-next",
                },
                hashNavigation: {
                  watchState: true,
                },
              }}
              items={orderedPhotosToShow}
              ItemComponent={EditionSlide}
              selectedPhotos={selectedPhotos}
              dispatchPhotoSet={dispatchPhotoSet}
              setData={setData}
              user={user}
            />
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        ) : (
          <h3>No photos to show</h3>
        )}
      </LightgalleryProvider>
      {activePhotoId && selectionState !== "complete" && (
        <div className="photo-sets__photo-selection">
          <SelectionIcon
            isSelected={selectedPhotos.includes(activePhotoId)}
            selectedPhotos={selectedPhotos}
            dispatchPhotoSet={dispatchPhotoSet}
            photo={activePhotoId}
          />
        </div>
      )}
    </>
  );
};

export default EditionPhotoSlider;
