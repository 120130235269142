import React, { useState, useEffect, useRef } from "react";
import GenericLayout from "layouts/GenericLayout";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "pages/Home";
import AccountDetails from "pages/AccountDetails/";
import Informations from "pages/Informations/";
import Gifts from "pages/Gifts/";
import Offers from "pages/Offers/";
import Billing from "pages/Billing/";
import CreditCards from "pages/CreditCards/";
import { PacksList, PackEdit, PackCreate } from "pages/Packs/";
import PhotoSetsList, {
  PhotoSet,
  PhotoSetCreate,
  PhotoSetEdit,
} from "pages/PhotoSets/index.js";
import { UsersList, UserEdit, UserCreate } from "pages/Users";
import Selections from "pages/Selections/";
import Logout from "modules/User/Logout/";
import Header from "layouts/Header";
import LoginForm from "modules/User/LoginForm";
import usersDataService from "services/users";
import http from "./http.js";
import propTypes from "prop-types";
import Loading from "components/molecules/Loading";

const App = () => {
  const [user, setUser] = useState(null);
  const [checklogin, setChecklogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setChecklogin(true);
      return;
    }
    getMe();
  }, []);

  const getMe = () => {
    usersDataService
      .me()
      .then((res) => {
        setLoading(false);
        setChecklogin(true);
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
        setChecklogin(true);
      });
  };

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(formRef.current);
    usersDataService
      .login(data)
      .then((res) => {
        Object.assign(http.defaults, {
          headers: { authorization: res.data.token },
        });
        localStorage.setItem("token", JSON.stringify(res.data.token));
        getMe();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const logout = (e) => {
    e && e.preventDefault();
    localStorage.removeItem("token");
    setUser(null);
    navigate("/", { replace: true });
  };

  const header = <Header logout={logout} user={user} />;
  return (
    <>
      {!checklogin ? (
        <Loading />
      ) : !user ? (
        <LoginForm login={login} formRef={formRef} loading={loading} />
      ) : (
        <>
          <GenericLayout header={header}>
            <Routes>
              {/* <Route path="/" element={<Home user={user} />} />
              <Route path="/accueil" element={<Home user={user} />} /> */}
              <Route path="/" element={<PhotoSetsList user={user} />} />
              <Route path="/accueil" element={<PhotoSetsList user={user} />} />
              <Route path="/account-details" element={<AccountDetails />} />
              <Route path="/informations" element={<Informations />} />
              <Route path="/gifts" element={<Gifts />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/credit-cards" element={<CreditCards />} />
              <Route path="/users" element={<UsersList user={user} />} />
              <Route path="/user/edit/:userId" element={<UserEdit />} />
              <Route path="/user/create" element={<UserCreate user={user} />} />
              <Route path="/packs" element={<PacksList user={user} />} />
              <Route
                path="/pack/edit/:packId"
                element={<PackEdit user={user} />}
              />
              <Route path="/pack/create" element={<PackCreate user={user} />} />
              <Route
                path="/photo-sets/list"
                element={<PhotoSetsList user={user} />}
              />
              <Route
                path="/photo-set/:setid"
                element={<PhotoSet user={user} />}
              />
              <Route path="/photo-set/create" element={<PhotoSetCreate />} />
              <Route path="/photo-set/edit/:setid" element={<PhotoSetEdit />} />
              <Route path="/selections" element={<Selections />} />
              <Route path="/logout" element={<Logout logout={logout} />} />
            </Routes>
          </GenericLayout>
        </>
      )}
    </>
  );
};

App.propTypes = {
  router: propTypes.func,
};

export default App;
