import React from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";

const PackCard = ({ packData }) => {
  const { data, name, type, state } = packData;

  const {
    packPrice,
    photosPerPack,
    additionalPhotoPrice,
    promoPrice,
    numberPhotosPromo,
  } = JSON.parse(data);
  return (
    <div className="pack-card">
      <div className="pack-card__header">
        {name} - {type != "" ? type : "normal"}
      </div>
      <div className="pack-card__content">
        <div className="pack-card__info">
          <div className="pack-card__info__item">
            <span>Prix : </span>
            <span>{packPrice} €</span>
          </div>
          <div className="pack-card__info__item">
            <span>Photos qty : </span>
            <span>{photosPerPack} photos</span>
          </div>
          <div className="pack-card__info__item">
            <span>Prix photo aditional : </span>
            <span>{additionalPhotoPrice} €</span>
          </div>
          <div className="pack-card__info__item">
            <span>Prix promo : </span>
            <span>{promoPrice} €</span>
          </div>
          <div className="pack-card__info__item">
            <span>Photos promo qty : </span>
            <span>{numberPhotosPromo} photos</span>
          </div>
        </div>
        <div className="pack-card__actions">
          <span className="pack-card__actions__edit">
            <Link to={`/pack/edit/${packData.packId}`}>edit</Link>
          </span>
          <span className="pack-card__actions__delete">delete</span>
        </div>
      </div>
    </div>
  );
};

PackCard.propTypes = {
  packData: propTypes.object,
};

export default PackCard;
