import React from "react";
import Btn from "components/atoms/Btn/Btn";
import propTypes from "prop-types";

const ConfirmSelectionMessage = ({ selectedPhotosQty, photosPerPack }) => {
  return selectedPhotosQty < photosPerPack ? (
    <>
      Il y a encore {photosPerPack - selectedPhotosQty} photos à sélectionner.
      Êtes-vous sûr de vouloir continuer ?
      <br />
      <br /> La validation sera définitive et{" "}
      <strong>vous ne pourrez pas revenir en arrière.</strong>
    </>
  ) : (
    <>
      Vous avez sélectionné {selectedPhotosQty} photos. Vous avez la possibilité
      d&apos;en choisir plus.
      <br /> Êtes-vous sûr de vouloir continuer ?<br />
      <br /> La validation sera définitive et{" "}
      <strong>vous ne pourrez pas revenir en arrière.</strong>
    </>
  );
};

ConfirmSelectionMessage.propTypes = {
  selectedPhotosQty: propTypes.number,
  photosPerPack: propTypes.number,
};

const ConfirmSelection = ({
  handleModal,
  handlecreateSelection,
  photosData,
}) => {
  const { photosPerPack, selectedPhotosQty } = photosData;

  return (
    <>
      <div className="message">
        <ConfirmSelectionMessage
          photosPerPack={photosPerPack}
          selectedPhotosQty={selectedPhotosQty}
        />
      </div>
      <div className="actions">
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handleModal}
        >
          Annuler
        </Btn>
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handlecreateSelection}
        >
          Confirmer
        </Btn>
      </div>
    </>
  );
};

ConfirmSelection.propTypes = {
  handleModal: propTypes.func,
  handlecreateSelection: propTypes.func,
  photosData: propTypes.object,
};

const ConfirmRemoveSet = ({ handleModal, handleRemoveSet }) => {
  return (
    <>
      <div className="message">here in the remove confirm</div>
      <div className="actions">
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handleModal}
        >
          Annuler
        </Btn>
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handleRemoveSet}
        >
          Confirmer
        </Btn>
      </div>
    </>
  );
};

ConfirmRemoveSet.propTypes = {
  handleModal: propTypes.func,
  handleRemoveSet: propTypes.func,
};

const RemovingSet = () => {
  return (
    <>
      <div className="message">Please wait</div>
    </>
  );
};

RemovingSet.propTypes = {
  handleModal: propTypes.func,
};

const DownloadLinkRejection = ({ handleModal, totalToPay }) => {
  return (
    <>
      <div className="message">
        Vous avez un solde de {totalToPay} €. <br />
        Une fois réglé, le lien de téléchargement apparaîtra.
      </div>
      <dic className="actions">
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handleModal}
        >
          ok
        </Btn>
      </dic>
    </>
  );
};

DownloadLinkRejection.propTypes = {
  handleModal: propTypes.func,
  totalToPay: propTypes.number,
};

const ConfirmDownloadLinkCreation = ({
  handleModal,
  handlecreateDownLoadLink,
}) => {
  return (
    <>
      <div className="message">
        Una vez que en enlace de descarga sea creado no podras modificar tu
        seleccion.
        <br /> Estas seguro que deseas continuar ?<br /> Aun puedes escojer mas
        fotos :)
      </div>
      <div className="actions">
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handleModal}
        >
          Cancel
        </Btn>
        <Btn
          className="create-selection"
          appearance="primary"
          onClick={handlecreateDownLoadLink}
        >
          Create Download link
        </Btn>
      </div>
    </>
  );
};

ConfirmDownloadLinkCreation.propTypes = {
  handleModal: propTypes.func,
  handlecreateDownLoadLink: propTypes.func,
};

const modalContents = ({
  handleModal,
  handlecreateSelection,
  handlecreateDownLoadLink,
  handleRemoveSet,
  photosData,
  totalToPay,
}) => {
  return {
    confirmSelection: {
      title: "Confirmer la sélection",
      component: (
        <ConfirmSelection
          handleModal={handleModal}
          handlecreateSelection={handlecreateSelection}
          photosData={photosData}
        />
      ),
    },
    confirmRemoveSet: {
      title: "Confirmer la suppresion deffinitive du set",
      component: (
        <ConfirmRemoveSet
          handleModal={handleModal}
          handleRemoveSet={handleRemoveSet}
        />
      ),
    },

    removingSet: {
      title: "Removing the set",
      component: <RemovingSet handleModal={handleModal} />,
    },
    confirmDownloadLinkCreation: {
      title: "Créer le lien de téléchargement",
      component: (
        <ConfirmDownloadLinkCreation
          handleModal={handleModal}
          handlecreateDownLoadLink={handlecreateDownLoadLink}
        />
      ),
    },
    downloadLinkRejection: {
      title: null,
      component: (
        <DownloadLinkRejection
          handleModal={handleModal}
          totalToPay={totalToPay}
        />
      ),
    },
  };
};

export default modalContents;
export {
  ConfirmSelection,
  ConfirmDownloadLinkCreation,
  ConfirmRemoveSet,
  RemovingSet,
};
