import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import propTypes from "prop-types";

import "swiper/css";

import "swiper/css/grid";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SliderOne = ({ sliderConf, items, ItemComponent, ...rest }) => {
  return (
    <Swiper {...sliderConf} className="slider-one">
      {items.map((item, key) => (
        <SwiperSlide key={key} data-hash={`page-${key}`}>
          <ItemComponent key={key} item={item} {...rest} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

SliderOne.propTypes = {
  sliderConf: propTypes.object,
  items: propTypes.array,
  ItemComponent: propTypes.func,
  isSelected: propTypes.bool,
  selectedPhotos: propTypes.array,
  dispatchPhotoSet: propTypes.func,
};

export default SliderOne;
