import React, { createContext, useState } from "react";
import propTypes from "prop-types";

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [httpProccesCounter, setHttpProccesCounter] = useState(0);
  return (
    <LayoutContext.Provider
      value={{
        isSaving,
        setIsSaving,
        httpProccesCounter,
        setHttpProccesCounter,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const LayoutConsumer = LayoutContext.Consumer;

LayoutProvider.propTypes = {
  children: propTypes.object,
};

export default LayoutContext;
