const photoSetReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_PHOTOSET_INIT":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_PHOTOSET_SUCCESS":
      const data = action.payload;
      console.log(data);
      const { dataPack } = data;
      const photosInSet = data.photos;
      data.photos =
        data.selectionState === "complete" ? data.selected : data.photos;
      data.photos = data.photos.split(",");
      data.photos.pop();
      data.photos = data.photos.map((photo, key) => ({
        photo: photo,
        id: key,
      }));
      data.selected = action.payload.selected.split(",");
      data.selected.pop();

      const billingData = {
        packPrice: Number(dataPack.packPrice),
        printPrice: Number(action.payload.printPrice),
        additionalPhotoPrice: Number(action.payload.additionalPhotoPrice),
        paid: action.payload.paid,
        photosPerPack: Number(dataPack.photosPerPack),
        additionalPhotoPrice: Number(dataPack.additionalPhotoPrice),
        promoPrice: Number(dataPack.promoPrice),
        numberPhotosPromo: Number(dataPack.numberPhotosPromo),
        packType: data.packType,
      };

      return {
        ...state,
        photos: data.photos,
        photosToShow: data.photos,
        selectedPhotos: data.selected,
        savedSelection: data.selected,
        selectionState: data.selectionState,
        loading: false,
        billingData: billingData,
        setData: {
          setFormat: data.setFormat,
          setId: data.setId,
          selectionState: data.selectionState,
          downloadLinkId: data.downloadLinkId,
          photos: photosInSet,
          cover: data.cover,
        },
        cover: data.cover,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "APPLY_FILTERS":
      var newPhotosToShow =
        state.filters.show === "all"
          ? state.photos
          : state.filters.show === "selected"
          ? state.photos.filter((item) =>
              state.selectedPhotos.includes(item.photo)
            )
          : state.photos.filter(
              (item) => !state.selectedPhotos.includes(item.photo)
            );

      switch (state.filters.order) {
        case "asc":
          newPhotosToShow = newPhotosToShow.sort((a, b) => a.id > b.id);
          break;
        case "desc":
          newPhotosToShow = newPhotosToShow.sort((a, b) => a.id < b.id);
          break;

        default:
          break;
      }
      return {
        ...state,
        photosToShow: newPhotosToShow,
      };
    case "SET_ACTIVE_PHOTO":
      return {
        ...state,
        activePhotoId: action.payload,
      };

    case "UNSET_ACTIVE_PHOTO":
      return {
        ...state,
        activePhotoId: null,
      };
    case "SET_SELECTED_PHOTOS":
      return {
        ...state,
        selectedPhotos: action.payload,
      };
    case "SELECT_ALL":
      return {
        ...state,
        selectedPhotos: state.photos.map((photo) => photo.photo),
      };
    case "UNSELECT_ALL":
      return {
        ...state,
        selectedPhotos: [],
      };
    case "SAVE_SELECTION":
      return {
        ...state,
        savedSelection: state.selectedPhotos,
        cover: state.setData.cover,
      };
    case "CREATE_SELECTION":
      var photosToShow = state.selectedPhotos.map((photo, key) => ({
        photo: photo,
        id: key,
      }));
      return {
        ...state,
        selectionState: "complete",
        photosToShow: photosToShow,
        setData: {
          ...state.setData,
          selectionState: "complete",
        },
      };
    case "MODIFY_SELECTION":
      var photosToShow = state.setData.photos.split(",");
      photosToShow.pop();
      photosToShow = photosToShow.map((photo, key) => ({
        photo: photo,
        id: key,
      }));
      return {
        ...state,
        selectionState: "pending",
        photosToShow: photosToShow,
        photos: photosToShow,
        setData: {
          ...state.setData,
          selectionState: "pending",
        },
      };
    case "CREATE_DOWNLOAD_LINK":
      return {
        ...state,
        setData: {
          ...state.setData,
          downloadLinkId: state.setData.setId,
        },
      };
    case "REMOVE_PHOTO":
      const imageId = action.payload.imageId;
      let newPhotos = state.photos.filter((item) => item.photo != imageId);
      newPhotos = newPhotos.map((item, key) => ({
        photo: item.photo,
        id: key,
      }));
      return {
        ...state,
        photos: newPhotos,
        photosToShow: newPhotos,
      };

    case "CHANGE_COVER":
      console.log(action.payload.cover);
      return {
        ...state,
        setData: {
          ...state.setData,
          cover: action.payload.cover,
        },
      };

    default:
      throw new Error("The action called doesn't exist");
  }
};

export default photoSetReducer;
