import React from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";

const Btn = ({ to, appearance, children, onClick, active }) => {
  return to ? (
    <Link
      to={to}
      className={`btn${appearance ? ` btn-${appearance}` : " default"}${
        active ? ` active` : ""
      }`}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`btn${appearance ? ` btn-${appearance}` : " default"}${
        active ? ` active` : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Btn.propTypes = {
  to: propTypes.string,
  appearance: propTypes.string,
  children: propTypes.string,
  onClick: propTypes.func,
  active: propTypes.bool,
};

export default Btn;
