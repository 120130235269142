import Icon from "components/atoms/Icon";
import React, { useState } from "react";
import Nav from "layouts/Navigator";
import propTypes from "prop-types";
import { LayoutConsumer } from "layouts/LayoutContext";

const Header = ({ logout, user }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <header className="main-header">
      <div className="main-header__inner">
        <div className="main-header__logo">
          <a href={process.env.REACT_APP_SITE_URI}>
            <img src="/img/logo.png" />
          </a>
        </div>
        <LayoutConsumer>
          {({ isSaving }) => {
            return (
              isSaving && <div className="main-header__saving">Saving</div>
            );
          }}
        </LayoutConsumer>
        <div className="nav-btn" onClick={toggleNav}>
          <Icon icon="abrir" />
        </div>
        <Nav
          toggleNav={toggleNav}
          isNavOpen={isNavOpen}
          logout={logout}
          user={user}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  logout: propTypes.func,
  user: propTypes.object,
};

export default Header;
